<template>
    <Page icon="mdi-account-group" :title="$t('routes.roles')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
            <v-btn 
                @click="dialog = true"
                class="primary"
            >
                <v-icon left>mdi-plus</v-icon> {{ $t("role.create.title") }}
            </v-btn>
        </ActionBar>

        <v-simple-table class="mt-3 table-layout: fixed width-100">
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('role.list.name')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text text-center">
                        <SortHeader 
                            :text="$t('role.list.default')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="default"
                            type="boolean"
                        />
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("role.list.edit") }}
                    </th>
                </tr>
            </thead>

            <tbody style="white-space: nowrap;">
                <tr v-for="(r, index) in roles" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">{{ r.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2">
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (r.info) ? r.info : $t("empty") }}
                            </span>
                        </tr>
                    </td>

                    <td class="text-center">
                        <v-icon v-if="r.default">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                        {{ (r.default) ? $t("yes") : $t("no") }}
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'role', params: { id: r._id, routefrom: 'roles' } }"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <RoleCreate 
            :entity="selectedEntity()" 
            v-if="dialog" 
            :open="dialog" 
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";
import RoleCreate from "../views/dialogs/RoleCreate.vue";

export default {
    components: {
        Page,
        Card,
        ActionBar,
        SortHeader,
        RoleCreate
    },

    async mounted() {
        await this.load();
    },

    data: () => ({
        progress: true,
        searchProgress: false,
        roles: null,
        dialog: false,

        pagination: {
            total: 0,
            filter: null
        },

        form: {
            page: 1,
            limit: null,
            filter: null,
            sort: "name ",
        }
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;

            this.searchProgress = !this.progress;

            this.selectedEntity();

            const res = await this.$store.dispatch("api/role/all", this.form);

            if (res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.roles = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        }
    }
};
</script>
